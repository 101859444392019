import React from 'react'
import Legends from './Legends'
import Usage from './Usage'
import Info from './Info'

const HelpCard = ({collapseBehaviour}) => {
    return (
        <div className="col-md-4" style={{minWidth:"300px"}}>
            <div className="card border-secondory mb-3">
                <div className="card-header">
                    <span role="img" aria-label=""></span>
                    Help                 
                       
                </div>
                <div className="card-body p-2 overflow-auto" style={{ minHeight: "100px" }}>
                    
                    <Legends />   
                    <Usage /> 
                    <Info collapseBehaviour={collapseBehaviour}/>
                    
                </div>       
            </div>
        </div>
    )
}

export default HelpCard
