import React from 'react'

const Usage = () => {
    return (
               <div className="accordion p-0 mt-2 hide-md" id="accordionExampleUsage">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#usage" aria-expanded="true" aria-controls="collapseTwo">
                            Usage
                        </button>
                        </h2>
                        <div id="usage" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExampleUsage">
                        <div className="accordion-body p-0">
                          <ul className="list-group">
                                        <li className="list-group-item">
                                            <p>
                                                First, select a State and District to see availability.
                                                This site remembers  your choice and next time it will automatically get availability  
                                                based on your earlier choice. Of course you can change you selection anytime.
                                            </p>
                                        </li>
                                        <li className="list-group-item">
                                            <p>
                                                Tap/Click &nbsp; 
                           <button type="button" disabled className="btn btn-outline-info btn-sm" target="_blank" rel="noreferrer"  
                            href="#">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                            </svg>&nbsp; Book at Cowin site </button> &nbsp; button to visit India government's Cowin site for booking.
                            
                            
                                            </p>
                            </li>
                                        <li className="list-group-item">
                                            <p> Tap/Click &nbsp; 
                                <button type="button" className="btn btn-outline-info btn-sm" target="_blank" rel="noreferrer" disabled>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                                </svg>&nbsp;
                                Refresh
                                                </button> &nbsp; button to re-check the availability anytime. This will get availability
                                                based on what you have already selected for State and District. This is helpful when 
                                                slot availability keep changing during peek time. In your next visit to this site
                                                all you have to do is to click this 'Refresh' button to get latest availability.                                                
                                                </p>
                            </li>
                                        <li className="list-group-item">
                                            <span> Tap/Click on&nbsp; 
                                            <span className="badge bg-light" style={{fontSize:14}}>
                                                <span>Age 40+</span>&nbsp;
                                                
                                                <span className="badge rounded-pill bg-success">10</span>&nbsp;
                                                <span className="badge rounded-pill bg-info">10</span>
                                                
                                                    &nbsp;<span>24-06-2021</span> 
                                                    
                                                </span> &nbsp;
                                                this section to show address, fee and slot infomration of the session; like below:
                                                <div className="mt-3 border p-2 text-muted" style={{ width: "300px"}}>
                                                              <strong>     <span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-at" viewBox="0 0 16 16">
                                                    <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"/>
                                                    </svg> Adress:
                                                    </span>
                                                    </strong>
                                                    <p>               
                                            NICE FHC, ABC Road, XYZ Town <br />Pin: 123456</p>
                                        <strong>₹ Fee:</strong>
                                        <p>COVISHIELD - ₹ 780</p>
                                                <span><strong>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-alarm" viewBox="0 0 16 16">
                                                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                                                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                                                    </svg>&nbsp;
                                                        Slots:</strong></span><br/>
                                                    <span>09:00AM-10:00AM</span><br/>
                                                    <span>10:00AM-11:00AM</span><br/>
                                                    <span>11:00AM-12:00PM</span>
                                                    </div>
                                            </span>
                            </li>                           
                        </ul>
                        </div>
                        </div>
                    </div>
                </div>
    )
}

export default Usage
