import React from 'react'

const Header = () => {
    return (
        <div className="mb-2">
        <div className="col-md-12">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">            
          <span className="badge rounded-pill bg-primary">
              <h5><span role="img" aria-label="">💉</span>VAAX.IN</h5></span>              
                    <div className="ms-1">                    
                 <span>COVID19 Vaccination Information</span>
             </div>
        </nav>
        </div>       
    </div>
    )
}

export default Header
