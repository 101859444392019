import React from 'react'
import SessionsCard from './SessionsCard'

const VaccInfoCard = ({ Heading, centers, spinnerVisible, onRefreshClick, refreshDisabled, message, vaccInfoCardStyle}) => { 

    const spinner = {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 100,
        left: 0
    };

    return (
       
        <div className="col-md-4" style={{ minWidth: "200px" }}>
            <div className="card border-secondory mb-3">
                <div className="card-header">
                    <div className="mb-2">
                    <span>{Heading}</span>
                    &nbsp;
                    
                        <span className="badge rounded-pill bg-success">Dose 1</span>&nbsp;&nbsp;
                        <span className="badge rounded-pill bg-info">Dose 2</span>
                        
                        </div>
                    <div>
                    <a type="button" className="btn btn-outline-info btn-sm" target="_blank" rel="noreferrer"  
                            href="https://selfregistration.cowin.gov.in/dashboard">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
                            <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
                            </svg>&nbsp;
                            
                            Book at Cowin site</a> &nbsp;&nbsp;
                    
                        <button type="button" className="btn btn-outline-info btn-sm" target="_blank" rel="noreferrer"
                            onClick={onRefreshClick} disabled={refreshDisabled}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                            </svg>&nbsp;
                            Refresh
                        </button>
                    </div>
                    
                </div>
                <div className="card-body p-2 overflow-auto" style={vaccInfoCardStyle}>   

                    {
                        centers.length > 0 ? (
                            
                            centers.map(center => (
                                <button type="button" className="list-group-item list-group-item-action" key={center.center_id}>
                                    <p className="font-weight-bolder mb-1">{center.name}</p>
                                                                       
                                    {                                           
                                        center.sessions.map(session => {                                             
                                            if (session.available_capacity > 0) {
                                              return  <SessionsCard session={session} center={center} key={"SC"+session.session_id} />
                                            }

                                            return <span key={"SC"+session.session_id}></span>
                                            // TODO:check if empties are added
                                        })
                                    }

                                </button>
                                
                            ))

                        ) : (                                
                                <div className="alert alert-light" role="alert">{message}</div>
                            )
                    }
                    
                    <div className={"d-flex justify-content-center "+spinnerVisible} style={spinner}>
                        <div className="spinner-border" role="status"></div>    
                    </div>    
                    
                </div>       
            </div>

        </div>        
    )
}

export default VaccInfoCard
