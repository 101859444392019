import React from 'react'

const SessionsCard = ({ session, center }) => {   

    var feeColorClass = center.fee_type === "Free" ? "success" : "danger";
    
    return (
    <React.Fragment>
            <div key={session.session_id} className="border-bottom mb-2" data-bs-toggle="collapse" href={"#s" + session.session_id}
                role="button" aria-expanded="false" aria-controls="sessionMoreDetails">
            
            <span className="badge bg-light" style={{fontSize:14}}>
                <span>Age {session.min_age_limit}+ </span> &nbsp;

                <span className="badge rounded-pill bg-success fw-bold">{session.available_capacity_dose1}</span>
                &nbsp;
                <span className="badge rounded-pill bg-info fw-bold">{session.available_capacity_dose2}</span>
                &nbsp;&nbsp;
                <span>{session.date}</span>
            </span>
            &nbsp;
            <div className="mb-2">
            <span className="badge rounded-pill bg-dark" style={{ fontSize: 10 }}>{session.vaccine}</span>
            &nbsp;&nbsp;
                <span className={"badge rounded-pill bg-" + feeColorClass} style={{ fontSize: 10 }}>{center.fee_type}</span>
            </div>    
        </div>
        <div className="collapse" id={"s"+session.session_id}>
                <div className="card card-body">
                    <strong>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-at" viewBox="0 0 16 16">
                        <path d="M13.106 7.222c0-2.967-2.249-5.032-5.482-5.032-3.35 0-5.646 2.318-5.646 5.702 0 3.493 2.235 5.708 5.762 5.708.862 0 1.689-.123 2.304-.335v-.862c-.43.199-1.354.328-2.29.328-2.926 0-4.813-1.88-4.813-4.798 0-2.844 1.921-4.881 4.594-4.881 2.735 0 4.608 1.688 4.608 4.156 0 1.682-.554 2.769-1.416 2.769-.492 0-.772-.28-.772-.76V5.206H8.923v.834h-.11c-.266-.595-.881-.964-1.6-.964-1.4 0-2.378 1.162-2.378 2.823 0 1.737.957 2.906 2.379 2.906.8 0 1.415-.39 1.709-1.087h.11c.081.67.703 1.148 1.503 1.148 1.572 0 2.57-1.415 2.57-3.643zm-7.177.704c0-1.197.54-1.907 1.456-1.907.93 0 1.524.738 1.524 1.907S8.308 9.84 7.371 9.84c-.895 0-1.442-.725-1.442-1.914z"/>
                        </svg> Adress:
                        </span>
                        </strong>
                        <p>               
                        {center.address}<br />Pin: {center.pincode}</p>
                    
                    {center.vaccine_fees != null ? (<strong>₹ Fee:</strong>) : ('')}
                    
                    {
                        center.vaccine_fees != null ? (                            
                            center.vaccine_fees.map(f => (
                                <p key={f.vaccine}>{f.vaccine} - ₹ {f.fee}</p>
                            ))
                        ) : ('')
                     }   


                    <span><strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-alarm" viewBox="0 0 16 16">
                        <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                        </svg>&nbsp;
                        Slots:</strong></span>
                    {
                        session.slots.map(slot => (
                            <span key={slot.replace(':','')}>{slot}</span>
                        ))
                    }
            </div>
        </div>
       </React.Fragment>     
    )
}

export default SessionsCard
