import React from 'react';
import Body from './containers/Body';
import Footer from './containers/Footer';
import Header from './containers/Header';

const App = () => {
  return (  
    <div className="container">
      
      <div className="row">
        <Header/>
      </div>
        
      <div className="row">
        <Body />
      </div>

      <div className="row">
        <Footer />
      </div>     
    </div>  
    
  )
}

export default App





