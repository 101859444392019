import React from 'react'
import MDUsage from './MDUsage'

const SelectionCard = ({
    provinces,
    districts,
    onProvinceSelect,
    onDistrictSelect,
    provinceDisabled,
    distDisabled,
    provinceSelected,
    districtSelected

    }) => {
    
    return (
        <div className="col-md-4">
                    <div className="form-group">
                        <div>
                    <select className="form-select" id="exampleSelect1"
                        onChange={onProvinceSelect}
                        disabled={provinceDisabled}
                        value={provinceSelected.toString()}
                    >
                                 <option value="0">Select state</option>
                                {
                            provinces.map(province => {
                                 
                                //console.log(province.state_id == provinceSelected)
                                //console.log(provinceSelected)

                            return (
                                <option
                                    key={province.state_id}
                                    value={province.state_id}
                                    // selected={province.state_id.toString() === provinceSelected.toString()}
                                >
                                    {province.state_name}
                                </option>)
                            
                            })
                                }
                            </select>
                        </div>
                <div className="mt-2 mb-2">
                    <select className="form-select" id="exampleSelect2"
                        onChange={onDistrictSelect}
                        disabled={distDisabled}
                        value={districtSelected.toString()}
                    >
                            <option value="0">Select district</option>
                                {
                                    districts.map(district => (
                                        <option
                                            key={district.district_id}
                                            value={district.district_id}
                                            // selected={district.district_id.toString() === districtSelected.toString()}
                                        >
                                            {district.district_name}
                                        </option>
                                    ))
                                }
                            </select>
                </div>
                <div className="text-end hide-sm">
                    <span className="text-muted">Availability &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                             <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                        </svg>
                    </span>
                </div>
            </div>            
            
               <MDUsage />
           
        </div>
    )
}

export default SelectionCard
