const axios = require('axios');

const apiClient = axios.create({
  baseURL: 'https://cdn-api.co-vin.in/api',
  withCredentials: false, 
  validateStatus: function (status) {
    return status < 500;
  }
});

export const getStates = () => {   
    return apiClient.get('/v2/admin/location/states') 
        .then(resp => resp)
        .catch(error => error);
};

export const getDistricts = (stateId) => {    
    return apiClient.get('/v2/admin/location/districts/' + stateId)
        .then(resp => resp)
        .catch(error => error.response);

};

export const getAvailabilityByDistricts = (distId) => {
    return apiClient.get('/v2/appointment/sessions/public/calendarByDistrict?district_id=' + distId
        + '&date=' + getToday())
        .then(resp => resp)
        .catch(error => error.response);
};

function getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return dd + '-' + mm + '-' + yyyy;
}

