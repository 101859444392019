import React from 'react'

const Legends = () => {
    return (
               <div className="accordion p-0" id="accordionlegends">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingLegends">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#legends" aria-expanded="false" aria-controls="collapseOne">
                            Legends
                        </button>
                        </h2>
                        <div id="legends" className="accordion-collapse collapse" aria-labelledby="headingLegends" data-bs-parent="#accordionlegends">
                        <div className="accordion-body p-0">
                            <ul className="list-group mp-0">
                                        <li className="list-group-item">
                                            <span className="badge rounded-pill bg-success">10</span> &nbsp; -
                                            shows first does avaiability
                                        </li>
                                        <li className="list-group-item">                                        
                                            <span className="badge rounded-pill bg-info">10</span> &nbsp; -
                                            shows second does avaiability                                        
                                        </li>
                                        <li className="list-group-item">
                                            <span className="badge bg-light" style={{fontSize:14}}>
                                                <span>Age 40+</span>
                                            </span> &nbsp; - age eligibility <br />
                                            <span className="badge bg-light mt-2" style={{fontSize:14}}>
                                                <span>24-06-2021</span> 
                                            </span> &nbsp; - date availability
                                            
                                        </li>
                                        <li className="list-group-item">
                                            <span className="badge rounded-pill bg-dark" style={{ fontSize: 10 }}>COVISHIELD</span>
                                            &nbsp; - shows Vaccine name
                                         </li>
                                        <li className="list-group-item">
                                            <span className="badge rounded-pill bg-success" style={{ fontSize: 10 }}>Free</span> &nbsp;
                                            <span className="badge rounded-pill bg-danger" style={{ fontSize: 10 }}>Paid</span> &nbsp;
                                            - Free or Paid
                                        </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
    )
}

export default Legends
