import React, { useEffect, useState } from 'react'
import HelpCard from '../components/HelpCard'
import SelectionCard from '../components/SelectionCard'
import VaccInfoCard from '../components/VaccInfoCard'
import * as api from '../services/api'

const Body = () => {

    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [centers, setCenters] = useState([]);
    const [distDisable, setDistDisable] = useState(true);
    const [provinceDisable, setProvinceDisable] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState("invisible");
    const [refreshDisabled, setRefreshDisabled] = useState(false);    
    const [centersMessage, setCentersMessage] = useState("Please select a State and Ditrict first to see availability");
    const [provinceSelected, setProvinceSelected] = useState("0");
    const [districtSelected, setDistrictSelected] = useState("0");
    const [mediaQueryBehaviour, setmediaQueryBehaviour] = useState({
        collapseBehaviour: {
            collapsed: "",
            show: " show",
            expand: "true"
        },
        vaccInfoCardStyle: {
            minHeight: "250px",
            maxHeight: "950px"
        }
    });


    useEffect(() => {
        api.getStates().then(resp => {
            
            if (resp.status === 200) {

                setProvinces(resp.data.states);
                
                //local storage check
                const provinceStoredId = localStorage.getItem("provinceStored");
                const districtStoredId = localStorage.getItem("districtStored");

                if (provinceStoredId) {
                    setProvinceSelected(provinceStoredId)
                    getDistricts(provinceStoredId)
                }

                if (districtStoredId) {
                    setDistrictSelected(districtStoredId)
                    AvailabilityByDistrict(districtStoredId)
                }
            }
            else {
                //catch all - could not get 403 status from resp object but just Network error during out of country, so..
                setCentersMessage("Could not get data! WARNING: This site may not work properly outside the India due to restrictions!");
                setProvinces([]);
            }
        })
            .catch(errorResp => {                
                setProvinces([]); //during any api error just clear stuffs
                //console.log(errorResp); //TODO: for now
            });            
        
    }, []);


    useEffect(() => {
        if (refreshDisabled)
            var timer = setTimeout(() => setRefreshDisabled(false), 5000);
        
         return () => clearTimeout(timer);
        
    }, [refreshDisabled])
  

    //manage screensize/mediaquery related stuffs
    useEffect(() => {
        
        //handle show/expand. Not a good way to do this. but for now!
        //the only caveat with this one is, when user re-size to small size on desktop it won't aut collapse
        //but looks like that's ok. Don't think a stet 
        const mdScreen = window.matchMedia("(min-width: 768px)").matches;     
        
        if (mdScreen) {
            setmediaQueryBehaviour({
                collapseBehaviour: {
                    collapsed: "",
                    show: " show",
                    expand: "true"
                },
                vaccInfoCardStyle: {
                    minHeight: "200px",
                    maxHeight: "75vh"
                }
            })
        }
        else {
            setmediaQueryBehaviour({
                collapseBehaviour: {
                    collapsed: " collapsed",
                    show: "",
                    expand: "false"
                },
                vaccInfoCardStyle: {
                    minHeight: "250px"
                    //no max height here in mobie devices
                }
            })
        }
    },[])


    function onProvinceSelect(e) {
        setCenters([])  //clear centers
        setDistricts([]) //clear districts
        localStorage.setItem("districtStored", "0"); //clear district storage       
        setCentersMessage("Please select a State and Ditrict first to see availability");
                
        localStorage.setItem("provinceStored", e.target.value); //store state selected

        setProvinceSelected(e.target.value);
        getDistricts(e.target.value);
    }

    function onDistrictSelect(e) {

        localStorage.setItem("districtStored", e.target.value);
        setDistrictSelected(e.target.value);
        AvailabilityByDistrict(e.target.value);
    }

    function getDistricts(stateId) {        

        if (stateId !== "0") {

            api.getDistricts(stateId).then(resp => {
                if (resp.status === 200) {
                    setDistricts(resp.data.districts);
                    setDistDisable(false);
                }
            })
                .catch(errResp => {
                    setDistricts([]); //during any api error just clear stuffs
                    //console.log(errResp); //TODO: for now
                });
            
        }
    }


    const AvailabilityByDistrict = (distId) => {

        if (distId !== "0") {

            api.getAvailabilityByDistricts(distId).then(resp => {

                setDistDisable(true);
                setProvinceDisable(true);
                setSpinnerVisible("visible");

                var filteredCenters = []

                if (resp && resp.status === 200) {
                    filteredCenters = filtertAvailableCenters(resp.data.centers)
                    setCenters(filteredCenters);                    
                }
                
                setDistDisable(false);
                setProvinceDisable(false);
                setSpinnerVisible("invisible");

                if (filteredCenters.length === 0 && districtSelected !== 0)
                    setCentersMessage("No availability found!");

            })
                .catch(errResp => {
                    setCenters([]); //during any api error just clear stuffs
                    //console.log(errResp); //TODO: for now
                });
            
        }
        else {
            setCenters([])  //clear centers      
            setCentersMessage("Please select a district");
        }
    };

    function filtertAvailableCenters(centers) {

        var filteredCenters = [];

        centers.forEach(center => {
            for (var i = 0; i < center.sessions.length; i++) {
                if (center.sessions[i].available_capacity > 0) {
                    filteredCenters.push(center);
                    break;
                }
            }
        });

        return filteredCenters;
    }

    function onRefreshClick(e) {        

        setRefreshDisabled(true);
        AvailabilityByDistrict(districtSelected);
    }

    return (
        <React.Fragment>
            {/* 1st column */}
            <SelectionCard
                provinces={provinces}
                districts={districts}
                onProvinceSelect={onProvinceSelect}
                onDistrictSelect={onDistrictSelect}
                provinceDisabled={provinceDisable}
                distDisabled={distDisable}
                provinceSelected={provinceSelected}
                districtSelected={districtSelected}
            />          
            
            {/* 2nd column */}
            <VaccInfoCard
                Icon=""
                Heading="District wise availability"
                centers={centers}
                spinnerVisible={spinnerVisible}
                onRefreshClick={onRefreshClick}
                refreshDisabled={refreshDisabled}
                message={centersMessage}
                vaccInfoCardStyle={mediaQueryBehaviour.vaccInfoCardStyle}
            />

            {/* 3rd column */}
            <HelpCard collapseBehaviour={mediaQueryBehaviour.collapseBehaviour}/>

            
            
        </React.Fragment>
    )
}

export default Body
